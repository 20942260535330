import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
const Post = () => {
  const [searchParams] = useSearchParams();
  const aff = searchParams.get("aff");
  const token = searchParams.get("token");

  let EndLink = "";
  if (aff) {
    EndLink += "&aff=" + aff;
  }
  if (token) {
    EndLink += "&token=" + token;
  }

  const [blogPost, setBlogtPost] = useState([]);
  let { id } = useParams();

  let apiUrl = window.location.origin;
  if (window.location.hostname === "localhost") {
    apiUrl = "https://arianltd.ir";
  }
  useEffect(() => {
    axios.get(`${apiUrl}/panel/v1/blog-post/view?id=` + id).then((result) => {
      setBlogtPost(result.data);
    });
  }, []);
  return (
    <>
      <div className="min-h-screen bg-gradient-to-r from-[#4f1bc5] to-[#7b17c5]">
        <div className="grid justify-center">
          <div className="flex">
            <Link to={"/?npm" + EndLink}>
              <button className=" bg-[#e4e7ee] mt-10 rounded-md shadow-md p-5 mx-3">برگشت به صفحه نخست</button>
            </Link>
            <Link to={`/post/list?${EndLink}`}>
              <button className=" bg-[#e4e7ee] mt-10 rounded-md shadow-md p-5 mx-3">برگشت به صفحه مقالات</button>
            </Link>
          </div>

          <div className="flex justify-center">
            <div className=" bg-white w-full  max-w-[1400px] my-5 rounded-md shadow-md p-5 mx-3">
              <img src={blogPost.img} className="rounded-md w-96" />
              <h2 className="mr-5 mt-5 text-[#4f1bc5] font-bold">{blogPost.title}</h2>
              <div className="mr-5 mt-5" dangerouslySetInnerHTML={{ __html: blogPost.full_text }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Post;
