import { FaBitcoin } from 'react-icons/fa';
import { FaTelegram } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaApple } from 'react-icons/fa';

const Box = (props) => {
    return(
        <div className="bg-[#f8f9fc] hover:bg-[#2f55d4] hover:text-white transition duration-200 p-10 m-10 rounded-md shadow-md">
                <div className="text-[#2f55d4]  hover:text-white flex justify-center py-4">
                    {props.icon === 'FaBitcoin' && (
                        <FaBitcoin  size={32} />
                    )}
                    {props.icon === 'FaTelegram' && (
                        <FaTelegram  size={32} />
                    )}
                    {props.icon === 'FaFacebookSquare' && (
                        <FaFacebookSquare  size={32} />
                    )}
                    {props.icon === 'FaApple' && (
                        <FaApple  size={32} />
                    )}
                    
                </div>
                
                <h3 className="pb-5 text-2xl text-center text-bold ">{props.title}</h3>
                <span className="text-gray-400">
                    {props.des}
                </span>
            </div>
    )
}
export default Box;