import "./App.css";
import bg from "./asset/img/bg.jpeg";
import girl from "./asset/img/3.jpeg";
import "tw-elements";
import Box from "./views/site/index/component/Box";
import DropDown from "./views/site/index/component/DropDown";
import DropDownFaq from "./views/site/index/component/DropDownFaq";
import { useEffect, useState } from "react";
import axios from "axios";
import TypeWriterEffect from "react-typewriter-effect";
import { FaCheck } from "react-icons/fa";
import FloatingWhatsApp from "react-floating-whatsapp";
import { FaBitcoin } from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css'

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import galleryPanel from "./asset/gallery/panel.png";
import galleryMyNumber from "./asset/gallery/myNumber.png";
import galleryChrgeSim from "./asset/gallery/chrgeSim.png";
import galleryInvoice from "./asset/gallery/invoice.png";
import galleryResiveSms from "./asset/gallery/resiveSms.png";
import gallerySendSms from "./asset/gallery/sendSms.png";

import galleryBuyPackge from "./asset/gallery/buyPackge.png";
import galleryAddNumber from "./asset/gallery/addNumber.png";

import telgramIcm from "./asset/img/telegram.gif";
import phoneGif from "./asset/img/icons8-phone.gif";
import whatsappGif from "./asset/img/icons8-whatsapp.gif";

import TelegramIcon from "./asset/img/TelegramIcon.png";
import twitterIcon from "./asset/img/twitterIcon.png";
import InstagramIcon from "./asset/img/InstagramIcon.png";

const settings = {
  dots: true,

  // infinite: true,
  // speed: 500,
  slidesToScroll: true,
  autoplay: true,
  slidesToShow: 1,
  arrows: false,
};

function Home() {
  const [searchParams] = useSearchParams();

  const queryParams = new URLSearchParams(window.location.search);
  const aff = searchParams.get("aff");
  const token = searchParams.get("token");
  if (token) {
    localStorage.setItem("pwaAccessToken", token);
  }
  const pwaAccessToken = localStorage.getItem("pwaAccessToken");

  if (["www.ariancharge.com", "ariancharge.com", "www.knightsim.net", "knightsim.net"].includes(window.location.hostname)) {
    if (!aff && !token) {
      console.log("token & af Not Set");
      window.location.href = window.location.href + "/panel";
    }
  }
  const isMilad = () => {
    if (["simcard.miladcard.com", "9it30m.ir", "simcard.padiza.ir", "simcard.seekaweb.ir"].includes(window.location.hostname)) {
      return true;
    }
    return false;
  };

  let EndLink = "";
  if (aff) {
    EndLink += "&aff=" + aff;
  }
  if (token) {
    EndLink += "&token=" + token;
  }
  let DontShowSimCard = false;
  // if(['simcard.mobilekomak.com'].includes(window.location.hostname)){
  //   DontShowSimCard = true
  // }

  let apiUrl = window.location.origin;
  if (window.location.hostname === "localhost") {
    apiUrl = "https://arianltd.ir";
  }
  console.log("apiUrl", apiUrl);
  // console.log('window.location.hostname222',)

  const [resellerData, setResellerData] = useState([]);
  const [faq, setFaq] = useState([]);
  const [simData, setSimData] = useState([]);
  const [isLogin, setIsLogin] = useState([]);
  const [dollarPrice, setDollarPrice] = useState([]);
  const [promotionText, setPromotionText] = useState([]);
  const [blogPost, setBlogtPost] = useState([]);
  useEffect(() => {
    axios.get(`${apiUrl}/panel/v1/auth/list-products`).then((result) => {
      setSimData(result.data.ProductData);
      setDollarPrice(result.data.dollarPrice);
      setPromotionText(result.data.promotionText);
    });

    axios.get(`${apiUrl}/panel/v1/faq/list`).then((result) => {
      setFaq(result.data);
    });

    axios.get(`${apiUrl}/panel/v1/blog-post/last`).then((result) => {
      setBlogtPost(result.data);
    });

    axios.get(`${apiUrl}/panel/site/reseller-data?domain=${window.location.hostname}`).then((result) => {
      setResellerData(result.data);
      // setDollarPrice(result.data.dollarPrice);
    });

    if (pwaAccessToken) {
      axios
        .get(`${apiUrl}/panel/v1/user/whoami`, {
          headers: { Authorization: `Bearer ${pwaAccessToken}` },
        })
        .then((result) => {
          setIsLogin(result.data);
        })
        .catch((result) => {
          localStorage.removeItem("pwaAccessToken");
        });
    }
  }, []);

  return (
    <>
      <nav class="navbar navbar-expand-lg shadow-md py-2 bg-gradient-to-r bg-[#FFFFFF]  relative flex items-center w-full justify-between">
        <div class="px-6 w-full flex flex-wrap items-center justify-between">
          <div class="flex items-center">
            <button
              class="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-[#004296] md:text-white  transition-shadow duration-150 ease-in-out mr-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContentY"
              aria-controls="navbarSupportedContentY"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  fill="currentColor"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                ></path>
              </svg>
            </button>
          </div>
          {/* <img className="block md:hidden" src="https://knightsim.net/assets/img/nikpardakht2.png" />   */}

          <div class="navbar-collapse collapse grow items-center" id="navbarSupportedContentY">
            <ul class="navbar-nav ml-auto lg:flex lg:flex-row">
              <li class="nav-item">
                <a
                  class="nav-link block pr-2 lg:px-2 py-2 text-[#004296] hover:text-[#007BFF] focus:text-gray-700 transition duration-150 ease-in-out"
                  href="panel"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  پنل فارسی
                </a>
              </li>
              {/* {
                resellerData.urlIntroduction && (
                  <li class="nav-item">
                    <a class="nav-link block pr-2 lg:px-2 py-2 text-white hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out" href={resellerData.urlIntroduction} data-mdb-ripple="true" data-mdb-ripple-color="light">سایت نماینده</a>
                  </li>
                )
              } */}
              <li class="nav-item">
                <a
                  class="nav-link block pr-2 lg:px-2 py-2 text-[#004296] hover:text-[#007BFF] focus:text-gray-700 transition duration-150 ease-in-out"
                  href="https://simcard.knightsim.com/"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  پنل بین المللی
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link block pr-2 lg:px-2 py-2 text-[#004296] hover:text-[#007BFF] focus:text-gray-700 transition duration-150 ease-in-out"
                  href={`/post/list?` + EndLink}
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  مقالات
                </a>
              </li>
              {!isMilad() && (
                <>
                <li class="nav-item">
                <a
                  class="nav-link block pr-2 lg:px-2 py-2 text-[#004296] hover:text-[#007BFF] focus:text-gray-700 transition duration-150 ease-in-out"
                  href={`${apiUrl}/panel/product/buy?type=simcard`}
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  خرید سیمکارت رند
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link block pr-2 lg:px-2 py-2 text-[#004296] hover:text-[#007BFF] focus:text-gray-700 transition duration-150 ease-in-out"
                  href={`${apiUrl}/panel/product/buy?type=virtual`}
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  خرید شماره رند
                </a>
              </li>
                </>
                
              )}
              
            </ul>
            {/* <img className="hidden md:block" src="https://knightsim.net/assets/img/nikpardakht2.png" />   */}
          </div>
        </div>
      </nav>
      <section className="flex justify-center bg-gradient-to-r bg-[#004296] " style={{ background: `linear-gradient(75deg, #4f1bc5, #7b17c5) !important` }}>
        <div className="grid  grid-cols-1 lg:grid-cols-3 mt-10  max-w-[1400px] pb-10">
          <div className="w-80">
            {/* <p className="text-white text-3xl sm:mb-10 text-right  sm:font-bold h-32">

              <div className="mb-10">
              <TypeWriterEffect
                textStyle={{
                  textAlign: 'center'
                }}

                startDelay={1000}
                cursorColor="#3F3D56"
                multiText={[
                  'خرید سیم کارت آمریکا',
                  'خرید سیم کارت کانادا',
                  'خرید سیم کارت انگلستان',
                ]}
                multiTextDelay={3000}
                typeSpeed={200}
              />
              </div>
              <TypeWriterEffect
                textStyle={{
                  marginTop: '10px',
                  textAlign: 'center'
                }}

                startDelay={2500}
                cursorColor="#3F3D56"
                multiText={[
                  'خرید شماره مجازی آمریکا',
                  'خرید شماره مجازی کانادا',
                ]}
                multiTextDelay={3000}
                typeSpeed={200}
              />

            </p> */}

            <img className="py-3 hidden lg:block" src={`${apiUrl}/assets/img/top2.png`} />

            <div className=" md:block bg-white rounded-md shadow-lg p-5 mb-5 hidden">
              <p className="text-[#3D424E]">امکانات پنل کاربری که پس از خرید در اختیار شما قرار میگیرد</p>
              <br />
              <br />
              <p className="text-[#004296]"> شماره خارجی:</p>
              <br />
              <div className="text-[#888888]">
                ✅ دریافت پیامک <br /> ✅ ارسال پیامک <br />✅ دایورت تماس به شماره موبایل ایران
              </div>
              <br />
              <p className="text-[#004296]">سیمکارت خارجی:</p>
              <br />
              <div className="text-[#888888]">
                ✅ دریافت پیامک <br />
                ✅ ارسال پیامک <br />
                ✅ دایورت تماس به شماره موبایل ایران <br />
                ✅ شارژ سیمکارت <br />
                ✅ فعال بسته های اینترنتی <br />
                ✅ دریافت موجودی حساب <br />
                ✅ صورت حساب سیمکارت <br />
              </div>
            </div>
          </div>

          <div className=" lg:col-span-2 bg-white w-80 md:w-[700px]	 shadow-lg p-5 rounded-md m-0 xl:m-0 ">
            <p className="mb-5 text-center text-red-800">
              <div dangerouslySetInnerHTML={{ __html: promotionText }} />
            </p>
            <p className="text-center mb-5 text-[#4E4E4E] font-bold text-4xl"> فرم سفارش محصول</p>
            {DontShowSimCard === false && (
              <>
                {/* <p className="mb-5 text-3xl text-red-700 text-center animate-pulse">افزایش قیمت</p>
                  <p className=" text-xl text-[#7b17c5] text-center">از اول مهر ماه سیمکارت ها ۱۰ دلار افزایش می یابد</p> */}
              </>
            )}

            <ul className="nav nav-pills flex flex-col md:flex-row list-none pl-0 mb-4" id="pills-tabFill" role="tablist">
                <>
                  <li className="nav-item flex-auto text-center my-2 md:mx-2" role="presentation">
                    <a
                      href="#pills-profileFill"
                      className=" !shadow-none	 nav-link w-full block font-medium text-lg leading-tight uppercase rounded px-6 py-3 focus:outline-none focus:ring-0 active"
                      id="pills-profile-tabFill"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profileFill"
                      role="tab"
                      aria-controls="pills-profileFill"
                      aria-selected="true"
                    >
                      خرید سیمکارت
                    </a>
                  </li>
                </>

              <li className="nav-item flex-auto text-center my-2 md:mr-2" role="presentation">
                <a
                  href="#pills-homeFill"
                  className="nav-link w-full block font-medium text-lg leading-tight uppercase rounded px-6 py-3 focus:outline-none focus:ring-0 "
                  id="pills-home-tabFill"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-homeFill"
                  role="tab"
                  aria-controls="pills-homeFill"
                  aria-selected="false"
                >
                  خرید شماره خارجی
                </a>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContentFill">
              <div className={`tab-pane fade ${DontShowSimCard === true ? "show active" : ""}`} id="pills-homeFill" role="tabpanel" aria-labelledby="pills-home-tabFill">
                {simData.virtual &&
                  Object.keys(simData.virtual).map((CountryEnName) => {
                    return <DropDown key={CountryEnName} id={CountryEnName} name={simData.virtual[CountryEnName].CountryName} CountryData={simData.virtual[CountryEnName]} dollarPrice={dollarPrice} />;
                  })}
              </div>
              <div className={`tab-pane fade ${DontShowSimCard === false ? "show active" : ""}`} id="pills-profileFill" role="tabpanel" aria-labelledby="pills-profile-tabFill">
                {/* <div className="bg-yellow-600 text-white p-4 mb-10 rounded-md">
                  شما میتوانید با خرید این شماره ها از قابلیت دریافت/ارسال پیامک در محیط کاربری وب سایت و در صورت فعال کردن دریافت تماس ،تماس های دریافتی خود را بر روی خط موبایل همراه ایران خود داشته باشید
                </div> */}

                {simData.simcard &&
                  Object.keys(simData.simcard).map((CountryEnName) => {
                    return <DropDown key={CountryEnName} id={CountryEnName} name={simData.simcard[CountryEnName].CountryName} CountryData={simData.simcard[CountryEnName]} dollarPrice={dollarPrice} />;
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="grid justify-center bg-white py-   ">
        <p className="mb-5 text-5xl text-red-700 text-center animate-pulse">افزایش قیمت</p>
        <p className=" text-xl text-[#7b17c5] text-center">از اول مهر ماه ۱۴۰۱</p>
        <p className=" text-xl text-[#7b17c5] text-center">سیمکارت ها ۱۰ دلار افزایش میابد</p>
        <div className='flex justify-center mt-10'>
            <div className='gird mx-10'>
                <div className='bg-[#151515] text-white p-2 rounded-md text-center w-14'>10</div>
                <p className='text-center'> ساعت</p>
            </div>
            <div className='gird place-content mx-10'>
                <div className='bg-[#151515] text-white p-2 rounded-md text-center w-14'>10</div>
                <p className='text-center'> روز</p>
            </div>

        </div>
      </section> */}

      <section className=" bg-[#fff] border-b-8 py-20   ">
        <div className="max-w-screen-sm md:max-w-screen-lg mx-auto  ">
          <p className="text-center mb-10">نمایی از پنل کاربری شما پس از سفارش</p>

          <div>
            <Slider {...settings}>
              <div>
                <p className="text-center mb-5 text-green-600  text-2xl ">داشبورد</p>
                <img className="border-2 border-[#4f1bc5]" src={galleryPanel} />
              </div>
              <div>
                <p className="text-center mb-5 text-green-600  text-2xl">شماره های من</p>
                <img className="border-2 border-[#4f1bc5]" src={galleryMyNumber} />
              </div>
              <div>
                <p className="text-center mb-5 text-green-600  text-2xl">شارژ سیمکارت</p>
                <img className="border-2 border-[#4f1bc5]" src={galleryChrgeSim} />
              </div>
              <div>
                <p className="text-center mb-5 text-green-600  text-2xl">صورتحساب سیمکارت</p>
                <img className="border-2 border-[#4f1bc5]" src={galleryInvoice} />
              </div>
              <div>
                <p className="text-center mb-5 text-green-600  text-2xl">پیامک های دریافتی شماره خارجی</p>
                <img className="border-2 border-[#4f1bc5]" src={galleryResiveSms} />
              </div>
              <div>
                <p className="text-center mb-5 text-green-600  text-2xl">ارسال پیامک با شماره خارجی</p>
                <img className="border-2 border-[#4f1bc5]" src={gallerySendSms} />
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="flex justify-center bg-white py-20 ">
        <div className=" max-w-[1400px] grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <Box icon="FaTelegram" title="وریفای شبکه های پیام رسان" des="با شماره های ما واتس آپ و تلگرام خودتان را با شماره های خارجی فعال کنید" />
          <Box icon="FaFacebookSquare" title="وریفای شبکه ای اجتماعی" des="با شماره های خارجی ما اینستاگرام و فیس بوک خودتان را فعال کنید" />
          <Box icon="FaBitcoin" title="وریفای صرافی های مختلف" des="بایننس، کوکوین، کوین بیس و … را با شماره خارجی ما وریفای کنید" />
          <Box icon="FaApple" title="وریفای سایتهای مختلف" des="آمازون، پی پال، گوگل، فری لنسر، فایور و ... را شماره های خارجی ما وریفای کنید" />
        </div>
      </section>

      <section className="flex justify-center bg-[#fff] py-20   ">
        <div className="grid gap-x-47 grid-cols-1 md:grid-cols-2 max-w-[1400px] ">
          <div className="w-full px-10">
            <img src={`${apiUrl}/assets/img/estonia.png`} className="mx-auto shadow-xl rounded-lg" />
          </div>

          <div className="w-full px-10">
            <h5 className="text-4xl mb-10 font-bold text-gray-700 mt-5">ویژگی های سیم کارت بین المللی نایت سیم</h5>

            <div className="border p-3 rounded-md mb-3">
              <b className="text-2xl text-cyan-700">سیم کارت فیزیکی</b>
              <p className="pt-3 text-gray-700">
                سیم کارت فیزیکی و تحت مالکیت خودتان به شما ارائه می شود و تا مادامیکه شرکت زیر ساخت ایران و نایت سیم فعال هستند و شما نیز شرایط فعال ماندن را رعایت کنید می توانید از خدمات آن استفاده
                کنید
              </p>
            </div>

            <div className="border p-3 rounded-md mb-3">
              <b className="text-2xl text-cyan-700">استفاده در ایران</b>
              <p className="pt-3 text-gray-700">
                می توانید هم زنگ زده و پیام ارسال کنید و هم تماس و پیام دریافت کنید . توصیه می کنم برای رسیدن به این هدف به صورت 100% حتما شماره اضافه مثل آمریکا یا انگلیس یا کانادا نیز جز انتخاب
                هایتان باشد
              </p>
            </div>

            <div className="border p-3 rounded-md mb-3">
              <b className="text-2xl text-cyan-700">وریفای انواع حساب</b>
              <p className="pt-3 text-gray-700">
                برای انواع وریفای حساب های مختلف از جمله بایننس ، پی پال ، فری لنسر ، فایور ، گوگل ، اپل و آمازون و اپلییکشن های پیامرسان مثل واتس آپ و تلگرام مناسب است
              </p>
            </div>

            <div className="border p-3 rounded-md mb-3">
              <b className="text-2xl text-cyan-700">متصل شدن 27 شماره</b>
              <p className="pt-3 text-gray-700">شما می توانید به انتخاب خودتان از 27 کشور مختلف شماره های مختلف را به شماره اصلی سیم کارت یعنی استونی اضافه کنید ( محدودیت در تعداد شماره نیست )</p>
            </div>
          </div>
        </div>
      </section>

      <div class="shadow-md py-2 bg-gradient-to-r bg-[#004296]  w-full ">
        <div className="text-center text-white text-xl p-6">
          <li className="py-5 text-gray-200">دریافت پیام، ارسال پیام، دریافت تماس، پنل کاربری آسان، اطلاع رسانی لحظه ای</li>
          <li className="py-5 text-gray-200">پنجره ای تازه برای رهایی از تحریم</li>
        </div>
      </div>

      <section className="flex justify-center bg-[#fff] py-20   ">
        <div className="grid gap-x-47 grid-cols-1 md:grid-cols-2 max-w-[1400px] ">
          <div className="w-full px-10">
            <h5 className="text-4xl mb-10 font-bold text-gray-700 mt-5">شماره خارجی</h5>
            <p className="text-pink-700">تعریف شماره خارجی در سیستم ما چیست؟</p>
            <p className="my-3 text-blue-700">
              شما میتوانید با خرید این شماره ها از قابلیت دریافت/ارسال پیامک در محیط کاربری وب سایت و در صورت فعال کردن دریافت تماس ،تماس های دریافتی خود را بر روی خط موبایل همراه ایران خود داشته
              باشید
            </p>

            <div className=" p-3 rounded-md mb-3">
              <p className="pt-3 text-gray-500 flex">
                <FaCheck size={64} className="ml-2 text-green-700" />
                این نوع شماره خارجی قبلا به کسی فروش نرفته و در اپلیکیشن ها و سایت های مالی متفاوت استفاده نشده است و در صورتی که شما شماره را تمدید نکنید از سیستم حذف میشود نه اینکه به شخص دیگری فروش
                برود.
              </p>
            </div>

            <div className=" p-3 rounded-md mb-3">
              <p className="pt-3 text-gray-500 flex">
                <FaCheck size={64} className="ml-2 text-green-700" />
                تا مادامی که این شماره را فعال نگه دارید میتوانید از دریافت پیامک و خروجی پیامک و دریافت تماس بهره مند شوید و مانند سایر شماره های خارجی یکبار مصرف نیست.
              </p>
            </div>

            {/* <div className=" p-3 rounded-md mb-3">
              <p className="pt-3 text-gray-500 flex">
                <FaCheck size={64} className="ml-2 text-green-700" />
                این شماره مجازی از قوی ترین شرکت ارائه دهنده شماره در آمریکا ارائه میشود و بهترین کیفیت را داراست.
              </p>
            </div> */}

            <div className=" p-3 rounded-md mb-3">
              <p className="pt-3 text-gray-500 flex">
                <FaCheck size={64} className="ml-2 text-green-700" />
                زمانی که شما از هر سیمکارت خارجی در ایران استفاده میکنید نگرانی شما قطع شدن سرویس و بسته شدن پورت های بین المللی است و ما با ارائه خدمات پیامک و تماس در محیط کاربری وبسایت مشکل شما را
                حل کرده ایم.
              </p>
            </div>
          </div>

          <div className="w-full px-10">
            <img src={girl} className="shadow-xl rounded-lg" />
          </div>
        </div>
      </section>

      <div class="shadow-md py-2 bg-gradient-to-r bg-[#E4E7EE]  w-full ">
        <div className="flex justify-start mx-5 mb-5">
          <Link to={`/post/list?` + EndLink}>
            <button className="bg-[#e4e7ee] w-40 py-3 mt-5">مشاهده همه مقالات</button>
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3  place-content-center  ">
          {blogPost &&
            blogPost.map((item) => {
              return (
                <div className="bg-white mx-5 rounded-md mb-5  ">
                  <img src={item.img} className="rounded-md w-80 mx-auto mt-2	" />
                  <h2 className="mr-5 mt-5 text-[#4f1bc5] font-bold">{item.title}</h2>
                  <p className="mx-5 mt-5 h-[240px]">{item.short_text}</p>
                  <Link to={`/post/${item.id}?` + EndLink}>
                    <button className="text-[#007BFF] py-3 mt-5 float-left ml-2">ادامه مطلب</button>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>

      <section className="flex justify-center bg-white pt-20 ">
        <div className="p-10 w-full md:w-3/4">
          <h5 className="text-4xl mb-10 font-bold text-gray-700">سوالات متداول (سیمکارت)</h5>
          {faq.map((date) => {
            if (date.type !== "sim") {
              return;
            }
            return <DropDownFaq key={date.id} id={date.id} name={date.question} description={date.answer} />;
          })}
        </div>
      </section>

      <section className="flex justify-center bg-white py-1 ">
        <div className="p-10 w-full md:w-3/4">
          <h5 className="text-4xl mb-10 font-bold text-gray-700">سوالات متداول (شماره خارجی)</h5>
          {faq.map((date) => {
            if (date.type !== "vir") {
              return;
            }
            return <DropDownFaq key={date.id} id={date.id} name={date.question} description={date.answer} />;
          })}
        </div>
      </section>

      {/* <div className="fixed bottom-0 right-0 m-4">
        <a href={resellerData.contactUsTelgram} target="_blank">
            <img src={telgramIcm} alt="Telegram Logo" className="w-12 h-12" />
        </a>
    </div> */}

      {/* {resellerData.contactUsType === 'telegram' && ( */}
      <div className=" fixed bottom-0 right  mr-5 mb-5  gap-y-1 flex justify-center items-center flex-col">
        {resellerData.contactUsSms && (
          <a href={`tel:${resellerData.contactUsSms}`} target={"_blank"}>
            <img src={phoneGif} className="rounded-full w-[48px] my-2" />
          </a>
        )}
        {resellerData.contactUsTelgram && (
          <a href={resellerData.contactUsTelgram} target={"_blank"}>
            <img src={telgramIcm} className="rounded-full w-12 my-2" />
          </a>
        )}
        {resellerData.contactUsWhatsapp && (
          <a href={`https://wa.me/${resellerData.contactUsWhatsapp}`} target={"_blank"}>
            <img src={whatsappGif} className="rounded-full w-12 my-2" />
          </a>
        )}
      </div>

      {/* // )} */}
      {/* {resellerData.contactUsType === 'WhatsApp' && (
        <FloatingWhatsApp phoneNumber={resellerData.contactUsWhatsapp} chatMessage="سلام، چطور بهتون میتونم کمک کنم؟" accountName='پشتیبانی' statusMessage="حداکثر زمان پاسخ گویی ۲ ساعت" />
      )} */}

      <section className="flex bg-[#004296] p-10 text-white ">
        <div className="flex flex-col md:flex-row w-full max-w-[1400px] justify-between">
          <div className="flex justify-center md:mr-28">
            <a href={resellerData.channelTelegram} target={"_blank"}>
              <img className="px-2 md:h-[40px]" src={TelegramIcon} />
            </a>
            <a href={resellerData.channelTwitter} target={"_blank"}>
              <img className="px-2 md:h-[40px]" src={twitterIcon} />
            </a>
            <a href={resellerData.channelInstagram} target={"_blank"}>
              <img className="px-2 md:h-[40px]" src={InstagramIcon} />
            </a>
          </div>
          {/* <img className="mt-5 md:h-[40px] md:mt-0" src="https://knightsim.net/assets/img/nikpardakht.png" /> */}
        </div>
      </section>
    </>
  );
}

export default Home;
