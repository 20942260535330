import React from "react";

const CustomLabel = (props)=> {
    return (
      <>
          <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
            {props.name}
        </span>
      </>
    );
}
export default CustomLabel;