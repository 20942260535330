import React from "react";

const CustomRadioInput = (props)=> {
    return (
      <>
          {/*<input type="radio" name={props.name}  id={props.id}  value={props.value} checked={props.checked ? true : false}  className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-right mr-2 cursor-pointer"/>*/}
          <input type="radio"  defaultChecked={props.checked ? true : false} name={props.name}  id={props.id}  value={props.value}  onChange={e => props.onChange(e.target.value)} className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-right mr-2 cursor-pointer"/>
          <label className="form-check-label inline-block text-gray-800 mr-1"
                 htmlFor={props.id} >{props.label}</label>
      </>
    );
}
export default CustomRadioInput;