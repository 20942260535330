import Home from './Home'
import Post from './views/site/post/Post'
import PostList from './views/site/post/PostList'
import { Routes, Route, Link } from "react-router-dom";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/post/list" element={<PostList />} />
      <Route path="/post/:id" element={<Post />} />
    </Routes>
  )
}

export default App;
