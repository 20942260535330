
import Modal from './Modal'
import arrow from '../../../../asset/img/arrow-down-sign-to-navigate.png';
import { useState } from 'react';
const TableRow = (props) => {
    let index = props.index;
    const [showPopUp, setShowPopUp] = useState(index === 0 ? true: false);
    let product = props.product;
    let CountryDataType = props.CountryDataType;
    let selectionList = props.selectionList;
    let dollarPrice = props.dollarPrice;
    return (
        <>
            <tr className={`${(index % 2 === 0) ? 'bg-gray-100' : 'bg-gray-400'} `} onClick={() => setShowPopUp(!showPopUp)}>

                <td className="text-sm text-gray-900 font-light p-2  text-center ">
                    {product.pishShomare}
                    <img src={arrow} className={`w-4  mx-auto ${showPopUp ? 'rotate-180' : 'animate-bounce'}`} />
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4  text-center">
                    {product.typeShomare}
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4  text-center">
                    <div dangerouslySetInnerHTML={{ __html: product.ability }} />
                </td>


            </tr>
            <tr className={`${(index % 2 === 0) ? 'bg-gray-100' : 'bg-gray-400'} ${showPopUp ? '' : 'hidden'} `}>
                <td colSpan={3} className=" text-sm text-gray-900 font-light px-1 py-4 text-center">
                    <Modal key={product.id} name={props.name} product={product} id={product.id} CountryDataType={CountryDataType} selectionList={selectionList} dollarPrice={dollarPrice} />
                </td>
            </tr>
        </>
    )
}
export default TableRow;