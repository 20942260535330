import { useState } from 'react';
import TableRow from './TableRow';
const DropDown = (props) => {
    let dollarPrice = props.dollarPrice;
    let products = (props.CountryData.products);

    let CountryDataType = props.CountryData.type;
    let selectionList = props.CountryData.selectionList;

    return (
        <div className="accordion" id={`accordion${props.id}`}>
            <div className="accordion-item bg-white border border-gray-200">
                <h2 className="accordion-header mb-0" id={`accordion${props.id}`}>
                    <button className=" collapsed accordion-buttonNew relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-[#f8f9fc] border-0 rounded-none transition focus:outline-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${props.id}`} aria-expanded="false"
                        aria-controls={`collapse${props.id}`}>
                        {props.name}
                    </button>
                </h2>
                <div id={`collapse${props.id}`} className="accordion-collapse collapse " aria-labelledby="headingOne"
                    data-bs-parent={`#accordion${props.id}`}>
                    <div className="accordion-body py-4 px-5">



                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                    <div className=" text-blue-800  mb-5 rounded-md text-[14px]">
                                            <div dangerouslySetInnerHTML={{ __html: props.CountryData.CountryDescription }} />
                                        </div>

                                        <table className="min-w-full">
                                            <thead className="bg-white border-b">
                                                <tr>

                                                    <th scope="col" className="text-sm font-medium text-gray-900 p-2  text-center">
                                                        پیش شماره
                                                    </th>
                                                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        نوع
                                                    </th>
                                                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        قابیت ها
                                                    </th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products && products.map((product, index) => (
                                                    <TableRow product={product} index={index} CountryDataType={CountryDataType} dollarPrice={dollarPrice} selectionList={selectionList} />
                                                ))}



                                            </tbody>
                                        </table>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DropDown;