
const DropDownFaq = (props) => {    
    return (
        <div className="accordion mb-2" id={`accordion${props.id}`}>
            <div className="accordion-item bg-[#f8f9fc] border border-gray-200">
                <h2 className="accordion-header mb-0" id={`accordion${props.id}`}>
                    <button className=" collapsed accordion-buttonNew relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-right bg-[#f8f9fc] border-0 rounded-none transition focus:outline-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${props.id}`} aria-expanded="false"
                        aria-controls={`collapse${props.id}`}>
                        {props.name}
                    </button>
                </h2>
                <div id={`collapse${props.id}`} className="accordion-collapse collapse " aria-labelledby="headingOne"
                    data-bs-parent={`#accordion${props.id}`}>
                    <div className="accordion-body py-4 px-5">
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                    <div dangerouslySetInnerHTML={{__html : props.description}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DropDownFaq;