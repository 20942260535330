import axios from "axios";
import Spinner from "../../components/Spinner";
import { useState, useEffect } from "react";
import { Link , useParams, useSearchParams} from "react-router-dom";
const PostList = () => {
    const [searchParams] = useSearchParams();
    const aff = searchParams.get('aff')
    const token = searchParams.get("token")
  
    let EndLink = ''
    if(aff){
      EndLink += '&aff=' + aff;
    }  
    if(token){
      EndLink += '&token=' + token;
    }  

    
    const [spinner, setSpinner] = useState(true);
    const [blogPost, setBlogtPost] = useState([]);
    let { id } = useParams();

    useEffect(()=>{
        axios.get('https://arianltd.ir/panel/v1/blog-post/index').then((result) => {
            setBlogtPost(result.data);
            setSpinner(false);
          })
    },[])
    return (
        <>
        <div className="min-h-screen bg-gradient-to-r from-[#4f1bc5] to-[#7b17c5]">
            
            
            <div className="grid justify-center">

                <div className="flex">
                    <Link to={`/?${EndLink}`}>
                        <button className=" bg-[#e4e7ee] mt-10 rounded-md shadow-md p-5 mx-3">
                            برگشت به صفحه نخست
                        </button>
                    </Link>
                    
                </div>

                {spinner && (
                    <div className="flex my-10 justify-center">
                        <Spinner />
                    </div>
                )}
                
                {!spinner && (
                    <div className="flex justify-center">
                    <div class=" py-2   w-full ">
                            
                            <div className='grid grid-cols-1 md:grid-cols-3  place-content-center  '>
                            {blogPost && blogPost.map((item) => {
                                return (
                                <div className='bg-white mx-3 rounded-md mb-5  '>
                                    <img src={item.img} className="rounded-md w-80 mx-auto	" />
                                    <h2 className='mr-5 mt-5 text-[#4f1bc5] font-bold'>{item.title}</h2>
                                    <p className='mr-5 mt-5'>
                                        {item.short_text}
                                    </p>
                                    <Link to={`/post/${item.id}?${EndLink}`}>
                                    <button className='bg-[#e4e7ee] w-full py-3 mt-5'>ادامه مطلب</button>
                                    </Link>
                                </div>
                                )
                            })}
                            </div>
                    </div>
                </div>
                )}
                

            </div>
        </div>
        </>
    )
}
export default PostList;